@import '../../theme.less';

.page-banner{
    position: relative;
    width: 100%;
    background-color: #717171;
    overflow: hidden;
    .default-banner{
        display: block;
        width: 100%;
    }
    .con{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 273px 10.4vw 0;
        box-sizing: border-box;
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
        }
    }
}
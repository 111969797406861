@import '../../theme.less';

.postForm{
    position: fixed;
    background-color: rgba(0,0,0,0.1);
    width: 100%;
    height: 100vh;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    .formCon{
        background-color: #fff;
        border-radius: @borderR;
        padding: 40PX 40PX 60PX;
        position: relative;
        box-shadow: 0rem 0rem 4rem rgba(0, 0, 0, 0.15);
        .close{
            position: absolute;
            top:1.5rem;
            right: 1.5rem;
            cursor: pointer;
            opacity: 0.6;
        }
        .formHead{
            text-align: center;
            color: @content;
            font-size: 22PX;
            width: 100%;
            p{
                text-align: center;
            }
        }
        .form{
            position: relative;
            .formTit{
                color: @content;
                font-size: 18PX;
                margin-top: 28PX;
            }
            input{
                width: 300PX;
                border: none;
                font-size: 18PX;
                line-height: 1.8;
                padding: 10PX 0;
                border-radius: 0;
                border-bottom: #999 solid 1PX;
            }
            .post{
                display: block;
                width: 100%;
                margin-top: 40PX;
                background-color: @primary;
                color: #fff;
                font-size: 18PX;
                line-height: 2.8;
                text-align: center;
                border-radius: 8PX;
                cursor: pointer;
            }
        }
    }
}
@import '../../theme.less';
.parter{
    width: 100%;
    position: relative;
    padding-bottom: 2rem;
    .swiper{
        border-radius: @borderR;
        height: 42rem;
    }
    .array{
        display: flex;
        // flex-flow: column;
        position: relative;
        width: 100%;
        .item{
            position: relative;
            display: flex;
            width: 25%;
            align-items: center;
            justify-content: center;
            height: 10.5rem;
            background-color: @gary;
            img{
                display: block;
                max-width: 17.5rem;
                max-height: 5.7rem;
                transition: opacity linear 0.3s;
            }
        }
        .item:nth-child(2n + 1){
            background-color: #fff;
        }
    }
    .array:nth-child(2n + 2){
        .item:nth-child(2n + 1){
            background-color: @gary;
        }
        .item:nth-child(2n + 2){
            background-color: #fff;
        }
    }
}
.parter:hover{
    .item{
        img{
            filter: grayscale(5);
            opacity: 0.3;
        }
    }
    .item:hover{
        img{
            filter: grayscale(0);
            opacity: 1;
        }
    }
}
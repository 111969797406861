.not-found-wrap {
  .not-found-head {
    min-height: 142px;
    background-color: #4D525C;
  }
  .not-found-body {
    width: 600px;
    height: 650px;
    background-size: contain;
    background-position: top center;
    background-image: url('../../public/404.png');
    background-repeat: no-repeat;
    margin: 0 auto;
  }
}
.iconBox{
    position: relative;
    display: flex;
    overflow: hidden;
    transform: rotate(0deg);
    transition: none;

    & > *{
        transition: none;
        transform: rotate(0deg);
    }
}
@import '../../theme.less';

.success{
    position: relative;
    width: 100%;
    padding-bottom: 8rem;
    background-color: #ffffff;
    .content{
        position: relative;
        width: 100%;
        box-sizing: border-box;
        .tab-head {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #EBEBEB;
            .tab-head-item {
                position: relative;
                font-size: 24px;
                font-weight: 500;
                text-align: left;
                color: #36312A;
                line-height: 33px;
                padding: 40px 0;
                margin-right: 180px;
                cursor: pointer;
                box-sizing: border-box;
                &:last-of-type {
                    margin-right: 0;
                }
                &.active {
                    color: @primary;
                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        width: 74px;
                        height: 7px;
                        content: '';
                        background: @primary;
                        border-radius: 100px 100px 0 0;
                        transform: translateX(-50%);
                    }
                }
            }
        }
        .tab-body {
            padding: 0 10.4vw;
        }
        .sucList{
            position: relative;
            margin-top: 4rem;
            .item{
                position: relative;
                width: 480px;
                min-height: 980px;
                box-sizing: border-box;
                padding: 2.58rem;
                border-radius: @borderR;
                box-shadow: 0rem 0rem 4rem rgba(0, 0, 0, 0.08); 
                box-sizing: border-box;
                &:hover{
                    .img{
                        background-position: bottom center;
                        div{
                            opacity: 1;
                        }
                    }
                }
                &.abc-bank-travel {
                    .data{
                        flex-wrap: wrap;
                        .ditem {
                            width:180px;
                            &:nth-of-type(1), &:nth-of-type(2) {
                                margin-bottom: 10px;
                            }
                            &:nth-of-type(3) {
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .img{
                    width: 100%;
                    height: 480px;
                    position: relative;
                    background-size: cover;
                    background-position: top center;
                    background-repeat: no-repeat;
                    border-radius: @borderR;
                    overflow: hidden;
                    transition: all ease 0.8s;
                    div{
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(0,0,0,0.8);
                        transition: all ease 0.4s;
                        opacity: 0;
                        img{
                            display: block;
                            width: 70%;
                            border-radius: @borderR;
                        }
                    }
                }
                .title{
                    font-size: 26px;
                    line-height: 38px;
                    color: #1e1e1e;
                    font-weight: 600;
                    margin-top: 40px;
                }
                .con{
                    min-height: 140px;
                    display: block;
                    color: #1e1e1e;
                    background-color: @gary;
                    padding: 20px 32px;
                    box-sizing: border-box;
                    border-radius: @borderR;
                    margin-top: 26px;
                    margin-bottom: 100px;
                    font-size: 18px;
                    line-height: 30px;
                    box-sizing: border-box;
                }
                .tip {
                    position: absolute;
                    right: 2.58rem;
                    bottom: 130px;
                    font-size: 14px;
                    text-align: center;
                    color: #787878;
                    line-height: 20px;
                }
                .data{
                    position: absolute;
                    left: 0;
                    bottom: 50px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    text-align: center;
                    padding-right: 10px;
                    margin-top: 1.8rem;
                    overflow: hidden;
                    .ditem{
                        position: relative;
                        display: flex;
                        padding: 0 18px;
                        flex-flow: column;
                        justify-content: center;
                        align-items: center;
                        .val{
                            color: @primary;
                            font-size: 19px;
                            font-weight: 600;
                            span{
                                font-size: 18px;
                            }
                        }
                        .tit{
                            text-align: center;
                            font-size: 16px;
                        }
                    }
                    .ditem::after{
                        content: '';
                        width: 1PX;
                        height: 100%;
                        background-color: #D8D8D8;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    .ditem:first-child{
                        margin-left: 0;
                        padding-left: 0;
                    }
                    .ditem:first-child::after{
                        content: none;
                    }
                }
            }
        }
        .item.news{
            position: relative;
            background-image: url(../../public/suc/nbg.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: 100%;
            .head{
                position: relative;
                display: flex;
                justify-content: space-between;
                .tit{
                    color: @content;
                    font-size: 2rem;
                    font-weight: bold;
                }
                .more{
                    color: #bcbcbc;
                    margin-right: 4rem;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    p{
                        font-size: 1.58rem;
                        line-height: 2.5rem;
                        margin-right: 0.5rem;
                    }
                }
            }
            .list{
                position: relative;
                margin-top: 3.7rem;
                .newItem{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 3.33rem;
                    cursor: pointer;
                    .left{
                        position: relative;
                        display: flex;
                        flex-flow: column;
                        justify-content: space-between;
                        margin-right: 2rem;
                        .tit{
                            color: @content;
                            font-size: 1.33rem;
                            transition:  all linear 0.3s;
                        }
                        .fot{
                            display: flex;
                            justify-content: space-between;
                            .type{
                                font-size: 1.33rem;
                                font-weight: 500;
                                line-height: 2.33rem;
                                color: #999999;
                            }
                            .date{
                                font-size: 1rem;
                                font-weight: 400;
                                line-height: 2.33rem;
                                color: #AFAFAF;
                            }
                        }
                    }
                    img{
                        display: block;
                        width: 10.2rem;
                    }
                    &:hover{
                        .left .tit{
                            font-weight: bold;
                            color: @primary
                        }
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
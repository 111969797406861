@import "../../theme.less";

.supplierPage {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  .supplier-main {
    width: 1200px;
    height: 635px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 3px;
    box-shadow: 0rem 0rem 4rem rgba(0, 0, 0, 0.15);
    // padding: 20px;
    box-sizing: border-box;
    position: relative;
    .process-in {
      width: 230px;
      height: 80px;
      border-radius: 18px;
      background-color: rgba(129, 179, 55, 0.8);
      border: 1px solid rgba(187, 187, 187, 1);
      line-height: 80px;
      color: #fff;
      font-size: 24px;
      font-family: PingFangSC-regular;
      font-weight: 600;
      text-align: center;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      top: -40px;
      .icon-edit {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
      }
    }
    .processCon {
      margin-top: -20px;
      .line-flex {
        display: flex;
        margin-top: 15px;
      }
      .process-item {
        width: 33.3%;
        position: relative;
        box-sizing: border-box;
        // padding-right: 60px;
        // padding-left: 80px;
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 420px;
          background-color: rgba(187, 187, 187, 1);
          right: 0;
          top: 0;
          z-index: 1;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        .process-item-top {
          text-align: center;
        }
        .title {
          color: #333;
          font-size: 24px;
          line-height: 40px;
          font-family: PingFangSC-regular;
          font-weight: bold;
        }
        .account {
          color: #999;
          font-size: 16px;
          font-family: PingFangSC-regular;
          margin-top: 10px;
        }
        .time {
          text-align: center;
          width: 112px;
          height: 24px;
          border-radius: 15px;
          border: 1px solid #bbb;
          color: #999;
          font-size: 14px;
          line-height: 24px;
          margin: 12px auto 0;
        }
        .icon {
          width: 35px;
          height: 35px;
        }
        .process-item-bottom {
          .text {
            color: #333;
            font-size: 16px;
            line-height: 32px;
            font-family: PingFangSC-regular;
            font-weight: bold;
            margin-top: 12px;
            text-align: center;
          }
          .txt-content {
            font-size: 14px;
            font-family: PingFangSC-regular;
            line-height: 22px;
            color: #666;
            text-align: center;
            margin-top: 15px;
            .txt_1{
              // padding-left: 32px;
              margin-top: 4px;
            }
          }
        }
      }
    }
    .process-btn {
      margin: 85px auto 0;
      text-align: center;
      width: 400px;
      height: 60px;
      border-radius: 18px;
      background-color: rgba(129, 179, 55, 1);
      border: 1px solid rgba(187, 187, 187, 1);
      font-size: 26px;
      font-family: PingFangSC-regular;
      color: #fff;
      font-weight: bold;
      line-height: 60px;
      cursor: pointer;
    }
  }
}

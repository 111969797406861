@import '../../theme.less';

.about{
    width: 100%;
    position: relative;
    .content{
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding: 0.5rem @secMargin 8rem;
        .top{
            position: relative;
            box-sizing: border-box;
            padding: 0.1rem 7.5rem 4.5rem;
            border-radius: @borderR;
            box-shadow: 0rem 0rem 4rem rgba(0, 0, 0, 0.08);
            background-color: #fff;
            margin-top: -9rem;
            .info{
                display: flex;
                justify-content: space-between;
                img{
                    display: block;
                    width: 29.8rem;
                    height: 19.5rem;
                    margin-left: 8.75rem;
                }
                p{
                    color: @content;
                    font-size: 1.67rem;
                    font-weight: 400;
                    line-height: 2.83rem;
                    margin-bottom: 3rem;
                }
                p:last-child{
                    margin-bottom: 3rem;
                }
                
            }
            .data{
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                width: 100%;
                color: @secContent;
                padding: 3.8rem 0 2rem;
                border-top: @gary solid 1PX;
                margin-top: 6rem;
                .item{
                    position: relative;
                    align-items: flex-end;
                    font-size: 1.5rem;
                    line-height: 1.5rem;
                    box-sizing: border-box;
                    .text{
                        display: flex;
                        justify-content: center;
                        align-items: baseline;
                        p{
                            margin: 0;
                        }
                        span{
                            font-size: 36px;
                            line-height: 2.7rem;
                            font-weight: 600;
                            color: @primary;
                        }
                        .unit {
                            font-size: 22px;
                            color: @primary;
                            font-weight: 600;
                        }
                    }
                    .title{
                        font-size: 16px;
                        line-height: 20px;
                        margin-top: 1.5rem;
                        color: #17181B;
                    }
                }
                .item::after{
                    content: '';
                    border-right: rgba(255,255,255,0.4) solid 1PX;
                    height: 50%;
                    width: 1PX;
                    position: absolute;
                    top: 25%;
                    right: -120%;
                }
                .item:last-child::after{
                    border-right: none;
                }
            }
        }
    }
    .development-wrap {
        position: relative;
        overflow: hidden;
        .development-title {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }
    }
    .m2{
        display: block;
        width: 100%;
    }
}
@import '../../theme.less';

.titles{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    margin: 6.6rem 0 4.1rem;
    h1{
        font-size: 3.75rem;
        font-weight: bold;
        line-height: 3.75rem;
        color: @content;
        text-transform: uppercase;
        margin: 0;
    }
    h2{
        font-size: 2.5rem;
        font-weight: 400;
        line-height: 3.75rem;
        color: @secContent;
        margin: 0;
        margin-top: 1.1rem;
    }
}
@import "../../theme.less";

.header {
  position: relative;
  width: 100%;
  padding: 4rem @indexMargin;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    cursor: pointer;
  }
  .menu {
    display: flex;
    align-items: center;
    height: 3.5rem;
    border: 0.5PX solid #b0b0b0;
    background: linear-gradient(180deg, #989696 0%, #414141 100%);
    box-shadow: 0rem 1rem 1rem rgba(0, 0, 0, 0.16);
    opacity: 1;
    box-sizing: border-box;
    // padding-left: 3vw;
    border-radius: 2rem;
    white-space: nowrap;
    .menuItem {
      font-size: 16PX;
      padding: 0 3.2vw;
      height: 3.5rem;
      line-height: 3.5rem;
      // margin-right: 6vw;
      color: #fff;
      border-radius: 2rem;
      &:first-child {
        padding: 0 4.2vw;
      }
      &:last-child {
        padding: 0;
      }
      a.mainText {
        color: #fff;
        text-decoration: none;
        position: relative;
        white-space: nowrap;
        height: 3.5rem;
        line-height: 3.5rem;
        display: block;
      }
      &.onset {
        background: linear-gradient(180deg, #868986 0%, #333232 100%);
        // opacity: 0.76;
        height: calc(3.5rem - 2PX);
        a.mainText {
          height: calc(3.5rem - 2PX);
          line-height: calc(3.5rem - 2PX);
        }
      }
      // a.mainText::after {
      //   content: "";
      //   width: 30PX;
      //   height: 3PX;
      //   position: absolute;
      //   background-color: @primaryOpa;
      //   left: 50%;
      //   margin-left: -15PX;
      //   bottom: -18PX;
      //   transition: all ease 0.3s;
      //   transform: translateY(5PX);
      //   opacity: 0;
      // }
      // a.mainText.onset::after {
      //   opacity: 1;
      //   transform: translateY(0);
      // }
      // a.mainText:hover::after {
      //   opacity: 0.7;
      //   transform: translateY(0);
      // }
      .select {
        position: absolute;
        // padding: 2rem 0;
        margin-left: -2rem;
        // border-radius: 5PX;
        transition: all linear 0.3s;
        transform: translateY(0.5rem);
        visibility: hidden;
        overflow: hidden;
        opacity: 0;
        background: hsla(0, 0%, 100%, 0.25) border-box;
        overflow: hidden;
        border-radius: 0.3em;
        box-shadow: 0 0 0 1PX hsla(0, 0%, 100%, 0.3) inset,
          0 0.5em 1em rgba(0, 0, 0, 0.6);
        text-shadow: 0 1PX 1PX hsla(0, 0%, 100%, 0.3);
        a {
          display: block;
          text-decoration: none;
          color: #333;
          padding: 1.6rem 7rem 1.6rem 2rem;
          // margin-bottom: 0.5rem;
          transition: all ease 0.3s;
          background: inherit;
          position: relative;
          // color: #fff;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.3);
          & > * {
            margin-right: 1rem;
          }
          &:hover {
            background-color: #fff;
            // color: #fff;
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: -30PX;
          z-index: -1;
          -webkit-filter: blur(20PX);
          filter: blur(20PX);
        }
      }
      .cooperation {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 14.83rem;
        height: 3.42rem;
        border: #fff solid 0.5PX;
        background: linear-gradient(
          180deg,
          #81cc5e 0%,
          #73bc51 40%,
          #59905c 86%,
          #64a667 100%
        );
        box-shadow: 0rem 0rem 0rem rgba(13, 76, 21, 0.75);
        opacity: 1;
        border-radius: 2rem;
        p {
          margin: 0 0 0 10PX;
          color: #fff;
        }
      }
      &:hover {
        .select {
          visibility: initial;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
    .menuItem:last-child {
      margin-right: 0;
    }
  }
}
.header.indexHeader {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
}
.header.indexHeader.headerw {
  a.mainText {
    color: #fff;
  }
  .cooperation {
    border: #fff solid 1PX;
    p {
      color: #fff;
    }
  }
}
@media screen and (max-width: 1400PX) {
  .header {
    .menu {
      .menuItem {
        font-size: 13PX;
      }
    }
  }
}

@media screen and (max-width: 1200PX) {
  .header {
    position: relative;
    width: 100%;
    padding: 4rem @indexMargin;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu {
      .menuItem {
        font-size: 12PX;
      }
    }
  }
}

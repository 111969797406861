@import '../../theme.less';

.footer{
    width: 100%;
    position: relative;
    // margin-top: -7rem;
    z-index: 0;
    .brand{
        width: 100%;
        height: 18.8rem;
        background-image: url(../../public/ftbg.jpg);
        background-position: top right;
        background-size: cover;
        overflow: hidden;
        .main{
            position: relative;
            background-color: @primaryOpa;
            box-sizing: border-box;
            padding: 7.5rem @indexMargin;
            color: #fff;
            margin-top: 19rem;
            .slogn{
                font-size: 2.92rem;
                font-weight: bold;
                margin-bottom: 5.8rem;
                span{
                    font-size: 3.1rem;
                    margin-right: 1rem;
                    &:last-child{
                        margin-left: 10rem;
                    }
                }
            }
            .con{
                display: flex;
                .item{
                    width: 17.5rem;
                    margin-right: 6.3rem;
                    .tit{
                        font-size: 1.67rem;
                        margin-bottom: 3.2rem;
                        position: relative;
                    }
                    .tit::after{
                        content: '';
                        width: 2.5rem;
                        height: 3PX;
                        background-color: #fff;
                        position: absolute;
                        left: 0;
                        bottom: -1.6rem;
                    }
                    .desc{
                        font-size: 1.33rem;
                        line-height: 2.5rem;
                    }
                }
                .item:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .webmap{
        background-color: #4D525C;
        position: relative;
        padding: 5.17rem @indexMargin;
        display: flex;
        justify-content: space-between;
        .left{
            position: relative;
            .item{
                position: relative;
                .title{
                    font-size: 1.17rem;
                    margin-bottom: 2.24rem;
                    color: #9A9A9A;
                }
                .val{
                    font-size: 1.67rem;
                    color: #fff;
                }
            }
            .item.top{
                margin: 5.5rem 0 1.83rem;
            }
        }
        .right{
            position: relative;
            display: flex;
            justify-content: end;
            .map{
                position: relative;
                display: flex;
                color: #fff;
                .item{
                    position: relative;
                    margin-left: 8.1rem;
                    .tit{
                        font-size: 1.67rem;
                        margin-bottom: 3.17rem;
                        cursor: pointer;
                    }
                    .child{
                        position: relative;
                        .nav{
                            font-size: 1.42rem;
                            margin-bottom: 1rem;
                            opacity: 0.7;
                            cursor: pointer;
                            transition: all linear 0.3s;
                        }
                        .nav:hover{
                            opacity: 1;
                        }
                    }
                }
            }
            .ctrl{
                position: relative;
                display: flex;
                flex-flow: column;
                align-items: flex-end;
                margin-left: 8rem;
                cursor: pointer;
                .qr{
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10rem;
                    p{
                        margin-top: 1.42rem;
                        font-size: 1rem;
                        font-weight: 400;
                        color: #9A9A9A;
                    }
                }
            }
        }
    }
    .info{
        background-color: #4D525C;
        position: relative;
        padding: 2.25rem @indexMargin;
        // display: flex;
        // justify-content: space-between;
        font-size: 1.17rem;
        font-weight: 400;
        color: #9A9A9A;
        p{
            user-select: initial;
            text-align: center;
        }
        span,a{
            user-select: auto;
        }
        .beian {
            margin-bottom: 10PX;
        }
        .beian a{
            transition: all linear 0.3s;
            cursor: pointer;
            color: #aaa;
            margin-right: 30PX;
            &:last-of-type {
                margin-right: 0;
            }
        }
        .beian a:hover{
            color: #fff;
        }
        .beian-icon {
          display: inline-block;
          width: 20PX;
          height: 20PX;
          margin-right: 4PX;
        }
    }
}
@media screen  and (max-width: 1200PX){
    .footer .webmap .right .map .item {
        position: relative;
        margin-left: 4.1rem;
    }
}
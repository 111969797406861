@import "../../theme.less";
@remTopX: 12;

.index {
  position: relative;
  width: 100%;
  padding-bottom: 5rem;
  .banner {
    position: relative;
    background-color: #888;
    // background: radial-gradient(50% 123.47% at 50% 50%, #000000 0%, #FFFFFF 100%), radial-gradient(100% 100% at 50% 100%, #E9FFD8 0%, #001356 100%), linear-gradient(238.72deg, #0066FF 0%, #00CD21 100%), radial-gradient(100% 188.01% at 76.14% 0%, #00CAF7 0%, #FF00C7 100%), linear-gradient(0deg, #00C2FF 0%, #FFC700 100%), radial-gradient(59.2% 100% at 50% 0%, #8000FF 0%, #0085FF 100%), radial-gradient(100% 148.07% at 0% 0%, #FC8800 0%, #00FF94 100%);
    // background-blend-mode: overlay, multiply, hard-light, color-dodge, color-burn, color-burn, normal;
    video {
      display: block;
      width: 100%;
      -webkit-user-drag: none;
    }
    .nbg {
      display: block;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    .floatImg {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
    }
    .content {
      position: absolute;
      width: 100%;
      z-index: 3;
      color: #fff;
      text-align: center;
      height: 100%;
      // background-color: rgba(0,0,0,0.2);
      h2 {
        margin-top: 33.25rem;
        font-size: 4.7rem;
        font-weight: 400;
        line-height: 0rem;
        color: #ffffff;
      }
      h1 {
        font-size: 5rem;
        font-weight: bold;
        line-height: 6.75rem;
        color: #ffffff;
        margin-top: 3.6rem;
      }
    }
    .data {
      position: absolute;
      // margin-top: 70.1rem;
      bottom: 172px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      padding: 0 @indexMargin;
      z-index: 3;
      color: #fff;
      .item {
        position: relative;
        align-items: flex-end;
        font-size: 1.5rem;
        line-height: 1.5rem;
        box-sizing: border-box;
        .text {
          display: flex;
          justify-content: center;
          align-items: baseline;
          p {
            margin: 0;
            color: #eee;
            text-shadow: 0PX 4.5PX 2PX rgba(0, 0, 0, 0.5);
            -webkit-text-stroke: 0.7PX #fff;
          }
          span {
            font-size: 2.7rem;
            line-height: 2.7rem;
            font-weight: 500;
            color: #e0e0e0;
            text-shadow: 0PX 4.5PX 2PX rgba(0, 0, 0, 0.5);
            -webkit-text-stroke: 0.7PX #fff;
          }
        }
        .title {
          font-size: 1.4rem;
          line-height: 1.4rem;
          margin-top: 1.5rem;
          text-shadow: 0PX 4PX 2PX rgba(0, 0, 0, 0.5);
        }
      }
    }
    .down {
      position: absolute;
      margin-top: 82.8rem;
      display: flex;
      justify-content: center;
      width: 100%;
      opacity: 0.8;
    }
  }
  .content {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 0 @indexMargin;
    transform: translate3d(0, 0, 0);
    .proeco {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 14rem;
      .item {
        width: 49%;
        border-radius: @borderR;
        overflow: hidden;
        box-shadow: 0rem 0rem 4rem rgba(0, 0, 0, 0.08);
        padding: 57px 64px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        transition: all linear 0.3s;
        cursor: pointer;
        .info {
          position: relative;
          color: #333;
          font-size: 20px;
          font-weight: 400;
          line-height: 36px;
          .name {
            display: flex;
            align-items: center;     
            font-size: 30px;
            font-weight: 600;
            line-height: 42px;
            transition: color 0s;
            margin-bottom: 12px;
            p {
              transition: all linear 0.3s;
              margin-right: 1.25rem;
            }
          }
          .title {
            display: flex;
            align-items: center;
            transition: color 0s;
            margin-bottom: 1.5rem;
            p {
              transition: all linear 0.3s;
              margin-right: 1.25rem;
            }
          }
          .text {
            height: 140px;
            border-top: rgba(102, 68, 68, 0.1) solid 1PX;
            padding-top: 2rem;
            padding-bottom: 2rem;
            box-sizing: content-box;
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .bottom-label {
              font-weight: 600;
            }
            .bottom-link {

            }
          }
        }
      }
      .item:hover {
        background-color: @primary;
        color: #fff;
        transform: translateY(-5PX);
        .info {
          color: #fff;
          .name {
          }
          .title {
            p {
              margin-right: 1.85rem;
            }
          }
          .text {
            color: #eee;
            border-top: rgba(255, 255, 255, 0.6) solid 1PX;
          }
        }
      }
      .item:nth-child(4n + 3),
      .item:nth-child(4n + 4) {
        margin-bottom: 0;
      }
    }
    .solution {
      position: relative;
      display: flex;
      justify-content: space-between;
      .left {
        width: 345px;
        position: relative;
        margin-right: 18px;
        .cases-group {
          padding: 30px 20px;
          background: #f5f6fa;
          border-radius: 16px;
          margin-bottom: 16px;
          .cases-group-top {
            margin-bottom: 20px;
            .cases-title {
              font-size: 28px;
              font-weight: 500;
              text-align: left;
              color: #61a231;
              line-height: 40px;
              padding-left: 16px;
              border-left: 4px solid #61a231;
            }
          }
          .cases-group-body {
            .item {
              display: flex;
              align-items: center;
              height: 90px;
              padding-left: 40px;
              background-color: @gary;
              border-radius: @borderR;
              margin-bottom: 0.67rem;
              transition: all linear 0.3s 0.1s;
              transform: translateZ(0rem) rotate(0deg);
              cursor: pointer;
              position: relative;
              .info {
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                margin-left: 20px;
                .en {
                }
                .ch {
                  color: @secContent;
                }
              }
              &:hover {
                background-color: rgba(97, 163, 49, 0.2);
              }
              &.set {
                background-color: @primary;
                transform: translateX(-0.5rem);
                .info {
                  .en {
                    color: #fff;
                  }
                  .ch {
                    color: #fff;
                  }
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .right {
        flex: 1;
        height: auto;
        position: relative;
        overflow: hidden;
        border-radius: @borderR;
        .item {
          width: 100%;
          height: 100%;
          border-radius: @borderR;
          overflow: hidden;
          transition: all linear 0.6s;
          background-position: top right;
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          transform: translateX(-3rem);
          opacity: 0;
          .main {
            width: 830px;
            height: 100%;
            transition: all linear 0.7s;
            transform: translateX(-5rem);
            background-color: @primaryOpa;
            color: #fff;
            box-sizing: border-box;
            padding: 115px 95px 0;
            .title {
              font-size: 32px;
              font-weight: 400;
              line-height: 45px;
              position: relative;
              margin-bottom: 5.4rem;
            }
            .title::after {
              content: "";
              width: 2.5rem;
              height: 4px;
              background-color: #fff;
              position: absolute;
              left: 0;
              margin-top: 5.65rem;
            }
            .con {
              font-size: 24px;
              font-weight: 400;
              line-height: 36px;
              margin-bottom: 60px;
            }
            .res {
              width: 367px;
              font-size: 24px;
              line-height: 33px;
              .res-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;
                .res-item-value {
                  font-size: 36px;
                }
                &:last-of-type {
                  margin-bottom: 0;
                }
                &.other {
                  margin-bottom: 1rem;
                  b{
                    display: block;
                    font-weight: normal;
                    text-align: center;
                    font-size: 1.33333rem;
                    margin-top: 8px;
                  }
                }
              }
            }
            .more {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              position: absolute;
              right: 82px;
              bottom: 74px;
              width: 116px;
              height: 44px;
              color: #ffffff;
              border: 2px solid #ffffff;
              border-radius: 6px;
              overflow: hidden;
              transition: all linear .3s;
              .more-title {
                margin-right: 1rem;
                display: block;
                font-size: 24px;
                line-height: 28px;
                padding-bottom: 6px;
              }
              .more-icon {
                width: 8px;
                height: 14px;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center right;
                background-image: url('../../public/icon/left-arrow-white.png');
              }
              &:hover {
                color: #61a231;
                background-color: #ffffff;
                .more-icon {
                  background-image: url('../../public/icon/left-arrow-green.png');
                }
              }
            }
          }
        }
        .item.set {
          transform: translateX(0);
          opacity: 1;
          .main {
            transform: translateX(0);
          }
        }
      }
    }
    .aboutus {
      width: 100%;
      height: 70.3rem;
      background-image: url(https://gt-omp.g-town.com.cn/officialImgs/us.2252e0f1.jpg);
      background-size: 100%;
      margin-top: -6rem;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      border: #fff solid 1PX;
      box-sizing: border-box;
      .main {
        width: 41.25rem;
        height: 63.5rem;
        padding: 10rem 4rem;
        box-sizing: border-box;
        background-color: @primaryOpa;
        border-top-right-radius: @borderR;
        border-bottom-right-radius: @borderR;
        background-image: url(https://gt-omp.g-town.com.cn/officialImgs/usbg.png);
        background-repeat: no-repeat;
        background-position: bottom left;
        color: #fff;
        .tit {
          font-size: 2.5rem;
          font-weight: bold;
          line-height: 4.58rem;
          margin-bottom: 6rem;
          position: relative;
        }
        .tit::after {
          content: "";
          position: absolute;
          width: 3.5rem;
          height: 4PX;
          background-color: #fff;
          left: 0;
          bottom: -2.5rem;
        }
        .con {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 3rem;
          margin-bottom: 75px;
        }
        .con:last-child {
          margin-bottom: 0;
        }
        .btn {
          border: 2px solid #ffffff;
          border-radius: 6px;
          font-size: 16px;
          display: inline-block;
          padding: 7px 24px;
          color: #fff;
          border-radius: 0.6rem;
          cursor: pointer;
          transition: all linear 0.3s;
        }
        .btn:hover {
          color: @primary;
          background-color: #fff;
        }
      }
    }
    &.coreCompetencies {
      padding-bottom: 138px;
      background: #f2f5fa;
      overflow: hidden;
      .core-body {
        .core-list {
          display: flex;
          justify-content: space-between;
          .core-item {
            position: relative;
            width: 471px;
            // margin-right: 54PX;
            border-radius: 12PX;
            overflow: hidden;
            box-shadow: 0rem 0rem 4rem rgba(0, 0, 0, 0.08);
            box-sizing: border-box;
            img {
              width: 100%;
              height: 100%;
            }
            &:last-of-type {
              margin-right: 0;
            }
            &::after {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              content: '';
            }
          }
        }
      }
    }
  }
  .contentCustom {
    width: 100%;
    height: 85.92rem;
    background-image: url(https://gt-omp.g-town.com.cn/officialImgs/bg);
    background-size: contain;
    box-sizing: border-box;
    padding: 0 @indexMargin 4.9rem;
    margin-top: 8.33rem;
    position: relative;
    overflow: hidden;
    .swiper {
      overflow: initial;
    }
    .swiper-slide-next,
    .swiper-slide-prev {
      opacity: 0;
    }
    .swiper-pagination {
      position: relative;
      display: flex;
      justify-content: space-between;
      bottom: initial;
      left: initial;
      border-bottom: #ccc solid 1PX;
      .swiper-pagination-bullet {
        width: auto;
        height: auto;
        background: none;
        opacity: 1;
        font-size: 2rem;
        display: flex;
        align-items: center;
        color: @content;
        padding-bottom: 1.75rem;
        position: relative;
        p {
          margin-left: 1rem;
        }
      }
      .swiper-pagination-bullet.swiper-pagination-bullet-active {
        color: @primary;
        .icon {
          transform: translateX(-2.2rem);
          filter: drop-shadow(@primary 2.2rem 0);
        }
      }
      .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
        content: "";
        width: 100%;
        height: 3PX;
        background-color: @primary;
        position: absolute;
        bottom: 0;
      }
    }
    .item {
      position: relative;
      height: 55.33rem;
      margin-top: 3rem;
      display: flex;
      img {
        display: block;
        width: 55.33rem;
      }
      .con {
        position: relative;
        width: 100%;
        margin-left: 3.1rem;
        color: @content;
        .head {
          display: flex;
          justify-content: space-between;
          .name {
            font-size: 3.33rem;
            font-weight: bold;
            line-height: 4.58rem;
          }
          .data {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-align: center;
            margin-top: 1.8rem;
            .ditem {
              position: relative;
              margin-left: 2.5rem;
              padding-left: 2.5rem;
              .val {
                color: @primary;
                font-size: 2.92rem;
                font-weight: 600;
                span {
                  font-size: 1.25rem;
                }
              }
              .tit {
                font-size: 1.25rem;
              }
            }
            .ditem::after {
              content: "";
              width: 1PX;
              height: 50%;
              background-color: #ccc;
              position: absolute;
              left: 0;
              top: 25%;
            }
            .ditem:first-child {
              margin-left: 0;
              padding-left: 0;
            }
            .ditem:first-child::after {
              content: none;
            }
          }
        }
        .blockTit {
          font-size: 2.5rem;
          font-weight: bold;
          display: block;
          padding-bottom: 5.6rem;
          position: relative;
        }
        .blockTit::before {
          content: "";
          width: 3.6rem;
          height: 3PX;
          background-color: @primary;
          position: absolute;
          bottom: 3rem;
        }
        .dev {
          position: relative;
          display: flex;
          margin-bottom: 4.17rem;
          font-size: 1.42rem;
          .paln {
            position: relative;
            display: flex;
            align-items: center;
            overflow: hidden;
            height: 11.6rem;
            padding: 2rem;
            box-sizing: border-box;
            border-radius: @borderR;
            .left {
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: center;
              p {
                font-size: 1.1rem;
                white-space: nowrap;
              }
              // width: fill-available;
            }
            .right {
              width: 0;
              opacity: 0;
              font-size: 1.1rem;
              margin-left: 2rem;
              transition: all ease 0.3s;
              color: #fff;
              // height: 100%;
              display: block;
              span {
                line-height: 2rem;
                vertical-align: middle;
              }
              // display: flex;
              // align-items: center;
            }
          }
          .paln.set {
            background-color: @primaryOpa;
            color: #fff;
            .right {
              width: 17.33rem;
              opacity: 1;
            }
          }
        }
        .info {
          font-size: 1.42rem;
          line-height: 2.33rem;
        }
      }
    }
    .btn {
      background-color: #fff;
      color: @primary;
      font-size: 1.67rem;
      display: block;
      width: fit-content;
      padding: 0.6rem 2rem;
      float: right;
      cursor: pointer;
      transition: all linear 0.3s;
      border-radius: 0.6rem;
      margin-top: 3rem;
    }
    .btn:hover {
      background-color: @primary;
      color: #fff;
    }
    .btn::after {
      clear: both;
    }
  }
}
@media screen and (max-width: 1300PX) {
  .index .contentCustom .item .con .dev {
    margin-bottom: 2rem;
    .paln {
      max-height: 11.6rem;
      .right {
        span {
          line-clamp: 4;
          box-orient: vertical;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          // display: block;
          overflow: hidden;
        }
      }
    }
  }
}
@media screen and (max-width: 1300PX) {
  // .content{
  //     .proeco{
  //         .item{
  //             width: 62.25rem;
  //             border-radius: @borderR;
  //             overflow: hidden;
  //             box-shadow: 0rem 0rem 4rem rgba(0, 0, 0, 0.08);
  //             margin-bottom: 2.5rem;
  //             padding: 3.58rem 4rem;
  //         }
  //     }
  // }
}

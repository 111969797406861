@import '../../theme.less';

.lastBanner{
    position: relative;
    width: 100%;
    overflow: hidden;
    .main{
        display: block;
        width: 100%;
    }
    .con{
        position: absolute;
        top: 0;
        left: 10.4vw;
        width: 100%;
        height: 100%;
        color: #fff;
        text-align: left;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        h2{
            font-size: 5rem;
            font-weight: 400;
            line-height: 7rem;
            color: #fff;
        }
        h4{
            display: block;
            width: 70rem;
            line-height: 3rem;
            margin-top: 1.17rem;
            font-size: 2.08rem;
            font-weight: 400;
            color: #fff;
        }
        .cooperation{
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0.8rem 1.8rem;
            border: #BCBCBC solid 1PX;
            border-radius: 40PX;
            margin-top: 2.83rem;
            opacity: 0.8;
            transition: all linear 0.3s;
            p{
                font-size: 1.5rem;
                line-height: 1.77rem;
                margin-left: 1rem;
                // margin: 0 0 0 10PX;
            }
            &:hover{
                opacity: 1;
            }
        }
    }
}
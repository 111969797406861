@import "../../../theme.less";

.product-hwg {
  padding-bottom: 50px;
  .product-hwg-body {
    .system-wrap {
      padding: 0 10.4vw 0;
      overflow: hidden;
      .system-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 120px;
        .system-left {
          width: 762px;
          img {
            display: block;
            width: 100%;
          }
        }
        .system-right {
          width: 637px;
          .system-rubric {
            .system-rubric-item {
              display: flex;
              align-items: center;
              width: 100%;
              height: 212px;
              padding: 0 60px 0 66px;
              background-image: url('../../../public/product/system-bg1.png');
              background-size: 100% 100%;
              box-sizing: border-box;
              margin-bottom: 50px;
              border-radius: 12px;
              overflow: hidden;
              box-shadow: 0rem 0rem 20px rgba(0, 0, 0, 0.08);
              .system-item-name {
                position: relative;
                font-size: 24px;
                font-weight: 600;
                color: #6da846;
                line-height: 36px;
                margin-bottom: 16px;
                padding-left: 32px;
                &::before {
                  position: absolute;
                  top: 13px;
                  left: 0;
                  width: 7px;
                  height: 7px;
                  background: #6da846;
                  border-radius: 50%;
                  content: '';
                }
              }
              .system-item-desc {
                opacity: 0.5;
                font-size: 18px;
                font-weight: 400;
                text-align: left;
                color: #131415;
                line-height: 36px;
              }
              &:nth-of-type(2n) {
                height: 212px;
                background-image: url('../../../public/product/system-bg2.png');
              }
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    .advantages-wrap {
      padding-bottom: 230px;
      background-image: url('../../../public/product/advantages-bg.png');
      background-size: 100% 100%;
      box-sizing: border-box;
      overflow: hidden;
      .advantages-body {
        padding: 0 10.4vw 0;
        display: flex;
        justify-content: space-between;
        .advantages-left {
          width: 384px;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0rem 0rem 20px rgba(0, 0, 0, 0.08);
          img {
            display: block;
            width: 100%;
          }
        }
        .advantages-right {
          width: 1074px;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0rem 0rem 20px rgba(0, 0, 0, 0.08);
          img {
            display: block;
            width: 100%;
          }
        }
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }
      }
    }
    .major-wrap {
      padding: 0 10.4vw 0;
      box-sizing: border-box;
      overflow: hidden;
      .major-body {
        .major-imgs {
          position: relative;
          img {
            display: block;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 136px;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
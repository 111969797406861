@import "../../../theme.less";

.product-hwh {
  .product-hwh-body {
    .hwh-group {
      .hwh-group-body {
        &::after {
          display: block;
          content: '';
          clear: both;
        }
        .hwh-group-head {    
          .group-head-title {
            font-size: 36px;
            font-weight: 500;
            color: #6aa740;
            line-height: 50px;
            margin-bottom: 10px;
          }
          .group-head-desc {
            font-size: 24px;
            font-weight: 400;
            color: #a5a5a5;
            line-height: 40px;
            margin-bottom: 60px;
          }
        }
        .hwh-group-con {
          position: relative;
          width: 1034px;
          display: flex;
          background: #ffffff;
          border-radius: 16px;
          box-shadow: 0 4px 20px rgba(0,0,0,0.1);
          // box-shadow: 0rem 0rem 2rem rgba(0, 0, 0, 0.08);
          &::after {
            display: block;
            clear: both;
            content: '';
          }
          .group-con-item {
            position: relative;
            display: flex;
            justify-content: center;
            width: 25%;
            float: left;
            border-right: 1PX solid #e4e7eb;
            box-sizing: border-box;
            transition: all linear .3s;
            &:last-of-type {
              border-right: none;
            }
            &::before {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: 0;
              content: '';
              background-color: transparent;
              transition: all linear .3s;
            }
            &.active {
              color: #ffffff;
              .group-con-title, .group-con-list {
                color: #ffffff !important;
                transform: translateY(-10px);
              }
              .con-list-item-num {
                color: #61A231 !important;
                background: #ffffff !important;
              }
              &::before {
                position: absolute;
                top: -18px;
                bottom: -18px;
                left: 0;
                right: 0;
                content: '';
                background: #61a231;
                border-radius: 16px;
              }
            }
            .group-con-title {
              position: relative;
              display: flex;
              align-items: center;
              color: #36312a;
              padding-top: 40px;
              padding-bottom: 20px;
              border-bottom: 1PX solid #e4e7eb;
              transition: all linear .3s;
              .group-con-title-icon {
                margin-right: 9px;
                overflow: hidden;
              }
              .group-con-title-text {
                font-size: 24px;
                font-weight: 600;
                text-align: left;
                line-height: 33px;
              }
            }
            .group-con-list {
              position: relative;
              padding: 19px 0;
              color: #333333;
              transition: all linear .3s;
              .group-con-list-item {
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                .con-list-item-num {
                  display: block;
                  width: 24px;
                  height: 24px;
                  font-size: 14px;
                  font-weight: Bold;
                  text-align: center;
                  color: #ffffff;
                  line-height: 24px;
                  background: #61a231;
                  border-radius: 50%;
                  margin-right: 6px;
                  transition: all linear .3s;
                }
                .con-list-item-text {
                  font-size: 18px;
                  text-align: center;
                  line-height: 25px;
                }
              }
            }
          }
        }
        .hwh-group-case-img {
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .hwh-group-content, .hwh-group-img {
          float: left;
        }
        .hwh-group-content {
          padding-bottom: 30px;
        }
      }
    }
    .shopping-mall-wrap {
      padding: 0 8.9vw 0;
      box-sizing: border-box;
      padding-bottom: 170px;
      overflow: hidden;
      .hwh-group {
        .hwh-group-body {
          .hwh-group-case-img {
            width: 295px;
            height: 461px;
            margin-left: 96px;
            margin-top: 39px;
            background-image: url('../../../public/product/hwh-case1.png');
          }
        }
      }
    }
    .life-wrap {
      padding: 0 8.9vw 0;
      background: #f4f6fb;
      padding-bottom: 200px;
      overflow: hidden;
      .hwh-group {
        .hwh-group-body {
          .hwh-group-head {
            text-align: right;
          }
          .hwh-group-case-img {
            width: 477px;
            height: 515px;
            margin-right: 24px;
            margin-top: 16px;
            background-image: url('../../../public/product/hwh-case2.png');
          }
        }
      }
    }
    .marketing-wrap {
      padding: 0 8.9vw 0;
      box-sizing: border-box;
      padding-bottom: 140px;
      overflow: hidden;
      .hwh-group {
        .hwh-group-body {
          .hwh-group-case-img {
            width: 479px;
            height: 485px;
            margin-left: 56px;
            margin-top: -19px;
            background-image: url('../../../public/product/hwh-case3.png');
          }
        }
      }
    }
    .strategy-wrap {
      padding: 0 10.4vw 0;
      height: 1115px;
      background: #f4f6fb;
      overflow: hidden;
      .content-body {
        display: flex;
        justify-content: space-between;
      }
      .strategy-left {
        width: 776px;
        height: 694px;
        margin-right: 66px;
        background-image: url('../../../public/product/strategy01.png');
        background-size: contain;
        .strategy-left-tags {
          position: relative;
          width: 100%;
          height: 100%;
          .tag-item {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 146px;
            height: 50px;
            text-align: center;
            font-size: 20px;
            padding: 0 26px;
            opacity: 0.5;
            background: rgba(255,255,255,0.80);
            border: 1px solid #ebebeb;
            border-radius: 26px;
            box-sizing: border-box;
            transform: scale(0.8);
            transition: all linear .3s;
            // &.tag-zyzh {
            //   width: 220px;
            //   padding: 0;
            // }
            &.active {
              opacity: 1;
              font-size: 20px;
              transform: scale(1);
              color: #61A231;
              background: rgba(255,255,255,0.80);
              border: 1px solid #60a131;
            }
          }
        }
      }
      .strategy-right {
        flex: 1;
        .strategy-right-title {
          font-size: 36px;
          font-weight: 500;
          text-align: right;
          color: #6aa740;
          line-height: 50px;
          margin-bottom: 10px;
        }
        .strategy-desc {
          font-size: 24px;
          font-weight: 400;
          text-align: right;
          color: #a5a5a5;
          line-height: 40px;
          margin-bottom: 98px;
        }
        .strategy-right-con {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          .strategy-con-item {
            width: 642px;
            height: 112px;
            padding: 24px 34px;
            background: #e9edf5;
            margin-bottom: 24px;
            cursor: pointer;
            box-sizing: border-box;
            transition: all linear .3s;
            .strategy-con-name {
              font-size: 24px;
              font-weight: 600;
              text-align: left;
              color: #000000;
              line-height: 33px;
              margin-bottom: 7px;
            }
            .strategy-con-desc {
              font-size: 16px;
              font-weight: 400;
              text-align: left;
              color: #5a5a5a;
              line-height: 22px
            }
            &::after {
              position: absolute;
              left: 0;
              top: 30px;
              bottom: 30px;
              width: 6px;
              content: '';
              background: transparent;
              transition: all .3s;

            }
            &.active {
              position: relative;
              background: #ffffff;
              box-shadow: 0px 4px 30px 0px rgba(0,0,0,0.12);
              transform: translateX(-5px);
              &::after {
                // position: absolute;
                // left: 0;
                top: 0;
                bottom: 0;
                // width: 6px;
                content: '';
                background: #6aa740;
              }
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
